import React from "react";
import "./style.scss";
import TeamAugmentation from "../../assets/Images/Homepage/OurServices/TeamAugmentation.png";
import AgilePods from "../../assets/Images/Homepage/OurServices/AgilePods.png";
import ProductDevelopment from "../../assets/Images/Homepage/OurServices/ProductDevelopment.png";
import { Link } from "gatsby";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import RevealAnimation from "../RevealAnimation";

function OurServicesCard() {
  const data = [
    {
      imageUrl: TeamAugmentation,
      title: "Team Augmentation",
      description:
        "Highly skilled resources augmented into your existing in-house team to help increase the velocity, frequency and quality of your software delivery",
      url: "/team-augmentation",
    },
    {
      imageUrl: AgilePods,
      title: "Agile Pods",
      description:
        "Create a dedicated delivery capability consisting of cross-functional resources to become an extension of your in-house delivery teams",
      url: "/digiryte-pods",
    },
    {
      imageUrl: ProductDevelopment,
      title: "Product Development",
      description:
        "Providing expert engineers to help design, develop and deliver quality software products that your costumers love using",
      url: "/product-development",
    },
  ];
  return (
    <div className="our-services-wrap body-container">
      {data.map((item, index) => (
        <div key={index} className="card-item">
          <div className="card-content">
            <RevealAnimation className="card-img-wrap">
              <img src={item.imageUrl} alt={item.title} className="card-img" />
            </RevealAnimation>
            <RevealAnimation component={"h2"} className="card-title">
              {item.title}
            </RevealAnimation>
            <RevealAnimation component={"p"} className="card-description">
              {item.description}
            </RevealAnimation>
          </div>
          <RevealAnimation className="card-btn-wrap">
            <Link className={`card-btn`} to={item.url}>
              <span className="card-btn-inner">
                Learn more <KeyboardArrowRightOutlinedIcon />
              </span>
            </Link>
          </RevealAnimation>
        </div>
      ))}
    </div>
  );
}

export default OurServicesCard;
